body,
html,
main {
  height: 100%;
  margin: 0;
}

main {
  flex-grow: 1;
}

.dood {
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
}

textPath {
  animation: slideText 10s linear infinite;
}

.border-custom {
  border: 6px solid var(--text-primary);
}
